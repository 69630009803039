import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  TextField,
} from '@mui/material';

const options = ['1k0pt', '5k0pt', '5k10pt', '5k15pt', 'Mlns0pt'];
const nations = [
  'Saxony',
  'Denmark',
  'Ukraine',
  'Prussia',
  'Spain',
  'Turkey',
  'Sweden',
  'France',
  'Poland',
];

type Player = 'PlayerA' | 'PlayerB';

const steps = [
  'Player A Bans',
  'Player B Bans',
  'Player A Picks for Game 1',
  'Player B Picks for Game 2',
  'Game 3 Decided',
];

const Drafting = () => {
  const [step, setStep] = useState(0);
  const [currentOption, setCurrentOption] = useState('');
  const [currentNation, setCurrentNation] = useState('');
  const [bannedOptions, setBannedOptions] = useState<string[]>([]);
  const [bannedNations, setBannedNations] = useState<string[]>([]);
  const [pickedOptions, setPickedOptions] = useState<Record<Player, string>>({
    PlayerA: '',
    PlayerB: '',
  });
  const [pickedNations, setPickedNations] = useState<Record<Player, string>>({
    PlayerA: '',
    PlayerB: '',
  });
  const [thirdGame, setThirdGame] = useState<{ option: string; nation: string } | null>(null);
  const [log, setLog] = useState<string[]>([]);

  const handleBan = (player: Player) => {
    if (currentOption && currentNation) {
      setBannedOptions((prev) => [...prev, currentOption]);
      setBannedNations((prev) => [...prev, currentNation]);
      addLog(`${player} banned ${currentOption} and ${currentNation}`);
      setCurrentOption('');
      setCurrentNation('');
      setStep((prev) => prev + 1);
    }
  };

  const handlePick = (player: Player) => {
    if (currentOption && currentNation) {
      setPickedOptions((prev) => ({ ...prev, [player]: currentOption }));
      setPickedNations((prev) => ({ ...prev, [player]: currentNation }));
      addLog(`${player} picked ${currentOption} and ${currentNation}`);
      setCurrentOption('');
      setCurrentNation('');
      if (step === 3) {
        calculateThirdGame();
      } else {
        setStep((prev) => prev + 1);
      }
    }
  };

  const calculateThirdGame = () => {
    const remainingOption = options.find(
      (option) => !bannedOptions.includes(option) && !Object.values(pickedOptions).includes(option)
    );
    const remainingNations = nations.filter(
      (nation) =>
        !bannedNations.includes(nation) && !Object.values(pickedNations).includes(nation)
    );
    const randomNation =
      remainingNations[Math.floor(Math.random() * remainingNations.length)];

    setThirdGame({ option: remainingOption || 'None', nation: randomNation || 'None' });
    addLog(`Game 3 will use ${remainingOption || 'None'} and ${randomNation || 'None'}`);
    setStep(4);
  };

  const isPlayerTurn = (player: Player) => {
    return (step === 0 && player === 'PlayerA') ||
           (step === 1 && player === 'PlayerB') ||
           (step === 2 && player === 'PlayerA') ||
           (step === 3 && player === 'PlayerB');
  };

  const addLog = (message: string) => {
    setLog((prev) => [...prev, message]);
  };

  const flushState = () => {
    const state = {
      step,
      currentOption,
      currentNation,
      bannedOptions,
      bannedNations,
      pickedOptions,
      pickedNations,
      thirdGame,
    };
    return JSON.stringify(state, null, 2);
  };

  const restoreState = (json: string) => {
    try {
      const state = JSON.parse(json);
      setStep(state.step || 0);
      setCurrentOption(state.currentOption || '');
      setCurrentNation(state.currentNation || '');
      setBannedOptions(state.bannedOptions || []);
      setBannedNations(state.bannedNations || []);
      setPickedOptions(state.pickedOptions || { PlayerA: '', PlayerB: '' });
      setPickedNations(state.pickedNations || { PlayerA: '', PlayerB: '' });
      setThirdGame(state.thirdGame || null);
      setLog(state.log || []);
    } catch (error) {
      console.error('Invalid JSON:', error);
    }
  };

  return (
    <Box padding={2}>
      <Typography variant="h4" align="center" marginBottom={2}>
        Pick and Ban - BO3
      </Typography>

      {/* Stepper */}
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={5}>
          <Typography
            variant="h5"
            align="center"
            sx={{
              color: isPlayerTurn('PlayerA') ? 'primary.main' : 'text.secondary',
              fontWeight: isPlayerTurn('PlayerA') ? 'bold' : 'normal',
            }}
          >
            Player A
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h5" align="center">
            VS
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant="h5"
            align="center"
            sx={{
              color: isPlayerTurn('PlayerB') ? 'primary.main' : 'text.secondary',
              fontWeight: isPlayerTurn('PlayerB') ? 'bold' : 'normal',
            }}
          >
            Player B
          </Typography>
        </Grid>
      </Grid>

      {/* Options and Nations */}
      <Box marginTop={4}>
        <Typography variant="h6">Options:</Typography>
        <Box display="flex" flexWrap="wrap" gap={2}>
          {options.map((option) => (
            <Button
              key={option}
              variant="contained"
              color={currentOption === option ? 'primary' : 'inherit'}
              disabled={bannedOptions.includes(option) || Object.values(pickedOptions).includes(option)}
              onClick={() => setCurrentOption(option)}
            >
              {option}
            </Button>
          ))}
        </Box>
      </Box>

      <Box marginTop={4}>
        <Typography variant="h6">Nations:</Typography>
        <Box display="flex" flexWrap="wrap" gap={2}>
          {nations.map((nation) => (
            <Button
              key={nation}
              variant="contained"
              color={currentNation === nation ? 'primary' : 'inherit'}
              disabled={bannedNations.includes(nation) || Object.values(pickedNations).includes(nation)}
              onClick={() => setCurrentNation(nation)}
            >
              {nation}
            </Button>
          ))}
        </Box>
      </Box>

      <Box marginTop={4}>
        {step < 4 && (
          <Button
            variant="contained"
            color={step < 2 ? 'secondary' : 'primary'}
            onClick={() =>
              step < 2
                ? handleBan(step === 0 ? 'PlayerA' : 'PlayerB')
                : handlePick(step === 2 ? 'PlayerA' : 'PlayerB')
            }
            disabled={!currentOption || !currentNation}
          >
            {step < 2 ? 'Ban' : 'Pick'}
          </Button>
        )}
      </Box>

      {/* Logs */}
      <Box marginTop={4}>
        <Typography variant="h6">Log:</Typography>
        <Card>
          <CardContent>
            <Box
              sx={{
                maxHeight: 200,
                overflowY: 'auto',
                padding: 1,
              }}
            >
              {log.map((entry, index) => (
                <Typography key={index} variant="body2">
                  {entry}
                </Typography>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Drafting;
