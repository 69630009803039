import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import Layout from "../../layout/layout";
import PageTitle from "../../components/PageTitle";
import GridRating, { PlayerProps } from "../../components/GridRating";
import Strings from "../../components/LocalizedStrings";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import "./rating.css";
import { GetRating } from "../../services/RatingService";
import { useParams } from "react-router-dom";
import { stringToEnum } from "../../helpers/ConvertHelper";

export enum RatingType {
    _main,
    _0pt1k,
    _0pt5k,
    _10pt5k,
    _15pt5k,
    _0ptMlns
}

export interface RatingProps {
    type: RatingType
}

const Ratings = (props : RatingProps) => {
    const top = 1000;
    // const ratingType = RatingType.RGeneral;
    const [title, setTitle] = useState<string>(Strings.ratings_title);
    const [playersDefault, setPlayersDefault] = useState<PlayerProps[]>([]);
    const [players, setPlayers] = useState<PlayerProps[]>([]);

    const getRatingTypeString = (type: RatingType): string => {
        switch (type) {
          case RatingType._main:
            return Strings.ratings_title;
          case RatingType._0pt1k:
            return Strings.rating_0pt1k;
          case RatingType._0pt5k:
            return Strings.rating_0pt5k;
          case RatingType._10pt5k:
            return Strings.rating_10pt5k;
          case RatingType._15pt5k:
            return Strings.rating_15pt5k;
          case RatingType._0ptMlns:
            return Strings.rating_0ptMlns;
          default:
            throw new Error("Invalid RatingType");
        }
    };

    useEffect(() => {
        GetRating(props.type, top+1).then((players) => {
            // var playersDefault = players.filter((p: PlayerProps) => p.name != "[KR.S]Antoxa");
            setPlayers(players);
            setPlayersDefault(players);
        });
    }, []);

    return (
        <Layout>
            <Grid container>
                <Grid item xs={12} textAlign={"center"} mb={5}>
                    <PageTitle text={getRatingTypeString(props.type)} />
                </Grid>
                <Grid item xs={12}>
                    <GridRating players={players} top={top}></GridRating>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Ratings;
