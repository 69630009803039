import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface BasicMenuProps {
    title: string;
    url: string;
    submenu : BasicMenuProps[]
}

export default function BasicMenu(props : BasicMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function getButton(title: string, url: string | null) {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      if (url !== null) {
        window.location.href = url;
      }
    };

    return (
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ my: 2, color: "white", display: "block" }}
      >
        {title}
      </Button>
    )
  };

  function getMenu(submenu: BasicMenuProps[]) {
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {submenu.map((menu) => (
          <MenuItem key={menu.title} onClick={() => {
            setAnchorEl(null);
            if (menu.url !== null) {
              window.location.href = menu.url;
            }
          }}>
            {menu.title}
          </MenuItem>
        ))}
      </Menu>
    )
  };

  return (
    <>
      {props.submenu.length === 0 ? (
        <>
          {getButton(props.title, props.url)}
        </>
      ) : (
        <>
          {getButton(props.title, null)}
          {getMenu(props.submenu)}
        </>
      )}
    </>
  );
}